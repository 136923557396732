
@import url('https://fonts.googleapis.com/css2?family=Atkinson+Hyperlegible&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


html {
    scroll-behavior: smooth;
  }


/* css for loader  */

body{
  background-color: #07182F;
}
.main{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);
  padding: 0px 0px;
  text-decoration: none;
  overflow: hidden;
}

.main::before{
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  bottom: 2px;
  width: 25%;

}
.main span:nth-child(1){
  position: absolute;
  top: 0;
  left: 90px;
  width: 100%;
  height: 4px;
  background-color: #64FFDA;
  animation: animate1 1.5s  linear 1;

}
@keyframes animate1{
  0%{
      transform: translateX(-100%);
  }
  100%{
      transform: translateX(100%);
  }
}
.main span:nth-child(2){
  position: absolute;
  bottom: 90px;
  right: 0px;
  height: 100%;
  width: 4px;
  background-color: #64FFDA;

  animation: animate2 1.5s linear  1;
  animation-delay: .5s;


}
@keyframes animate2{
  0%{
      transform: translateY(-100%);
  }
  100%{
      transform: translateY(100%);
  }
}
.main span:nth-child(3){
  position: absolute;
  bottom: 0;
  right: 90px;
  height: 4px;
  width: 100%;

  background-color: #64FFDA;

  animation: animate3 1.5s linear 1;
  animation-delay: 2s;



}
@keyframes animate3{
  0%{
      transform: translateX(100%);
  }
  100%{
      transform: translateX(-100%);
  }
}
.main span:nth-child(4){
  position: absolute;
  top: 90px;
  left: 0;
  height: 100%;
  width: 4px;
  background-color: #64FFDA;

  animation: animate4 1.5s linear 1;
  animation-delay: 2.5s;


}
@keyframes animate4{
  0%{
      transform: translateY(100%);
  }
  100%{
      transform: translateY(-100%);
  }
}